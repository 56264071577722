import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import Input from "../elements/Input";
import Image from "../elements/Image";

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool
};

const defaultProps = {
  ...SectionProps.defaults,
  split: false
};

const Stack = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {
  const outerClasses = classNames(
    "cta section center-content-mobile reveal-from-bottom",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "section-inner featured-content",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider",
    split && "cta-split"
  );

  return (
    <section>
      <div className="container">
        <div className={innerClasses}>
          <div className="cta-slogan">
            <Image
              src={require("./../../assets/images/rtbias1.png")}
              alt="Features split 03"
              width={528}
              height={396}
            />
          </div>
          <div className="cta-action">
            <h3 style={{ textAlign: "center" }}>How It's Built</h3>
            <ul>
              <li>
                Responsive webapp built with Reactjs frontend with
                Nodejs/Express backend
              </li>
              <li>
                Redux for state management and utilizing modified version of
                Google's Material UI
              </li>
              <li>
                MongoDB for database with 3rd party API + scraping integration
                for news feed
              </li>
              <li>
                Natural language processing for topic comparison and cronjob for
                automating daily posts
              </li>
              <li>
                Mobile app for IOS and Android currently under development using
                React Native
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

Stack.propTypes = propTypes;
Stack.defaultProps = defaultProps;

export default Stack;
