import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";
import SkillBar from "react-skillbars";

const propTypes = {
  ...SectionTilesProps.types
};

const defaultProps = {
  ...SectionTilesProps.defaults
};
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "features-tiles section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-tiles-inner section-inner pt-0",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames(
    "tiles-wrap center-content",
    pushLeft && "push-left"
  );

  const sectionHeader = {
    title: "Tech and Tools I Use"
    // paragraph: 'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum — semper quis lectus nulla at volutpat diam ut venenatis.'
  };

  const SKILLS = [
    {
      type: "Mongodb",
      level: 80
    },
    {
      type: "Express",
      level: 90
    },
    {
      type: "Reactjs",
      level: 95
    },
    {
      type: "Nodejs",
      level: 90
    },
    {
      type: "R.Native",
      level: 90
    },
    {
      type: "HTML",
      level: 100
    },
    {
      type: "CSS/SASS",
      level: 100
    },
    {
      type: "Javascript",
      level: 100
    },
    {
      type: "PHP",
      level: 80
    }
  ];

  const colors = {
    bar: "#3498db",
    title: {
      text: "black",
      background: "white"
    }
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <div className="featured">
            <SectionHeader data={sectionHeader} className="center-content" />
            <SkillBar skills={SKILLS} colors={colors} animationDelay={1000} />
          </div>
        </div>
      </div>
    </section>
  );
};

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;
